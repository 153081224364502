import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import { createGtm } from '@gtm-support/vue-gtm';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import router from '@/router';
import i18n from '@/locales';
import vSelect from 'vue-select';
import VueSignaturePad from 'vue-signature-pad';

import App from '@/App.vue';
import 'vue-select/dist/vue-select.css';
import './assets/scss/index.scss';
import 'katex/dist/katex.min.css';

import Spinner from '@/components/utils/Spinner.vue';
import clickOutside from '@/directives/clickoutside.directive';
import markdownDirective from '@/directives/markdown.directive';

const app = createApp({
  render: () => h(App),
});

app.use(i18n);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.use(router);

if (import.meta.env.VITE_GTM_ID) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID,
      enabled: !!import.meta.env.VITE_GTM_ID,
      debug: false,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    })
  );
}

app.use(VueSignaturePad);

app.component('VSelect', vSelect);
app.component('Spinner', Spinner);

app.directive('markdown', markdownDirective);
app.directive('click-outside', clickOutside);

// Imported late so that everything else has been loaded

app.mount('#app');
