<template>
  <router-view />
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted, watch } from 'vue';
import {
  getCalculatedColorScheme,
  setLocalColorScheme,
} from '@/services/theme.service';
import { ColorScheme } from '@/enums/channel';
import { useSessionStore } from './stores/session.store';
import { useRoute } from 'vue-router';

const route = useRoute();

onMounted(() => {
  const initialScheme: ColorScheme = getCalculatedColorScheme();
  setLocalColorScheme(initialScheme);
});

const setFocusState = (state: boolean) => {
  useSessionStore().windowFocusState = state;
};

const setWindowSize = () => {
  useSessionStore().isMobile = window.innerWidth < 768;
};

onMounted(() => {
  setWindowSize();
  window.addEventListener('focus', () => setFocusState(true));
  window.addEventListener('blur', () => setFocusState(false));
  window.addEventListener('resize', () => setWindowSize());
});

onBeforeUnmount(() => {
  window.removeEventListener('focus', () => setFocusState(true));
  window.removeEventListener('blur', () => setFocusState(false));
  window.removeEventListener('resize', () => setWindowSize());
});

watch(
  () => route.query,
  (val) => {
    /**
     * Load Appcues ONLY for users that are redirected with this specific query param
     */
    if (val?.guidedtour === 'true') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).AppcuesSettings = {
        enableURLDetection: true,
      };
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//fast.appcues.com/121231.js';
      head.appendChild(script);
    }
  }
);
</script>
