import showdown from 'showdown';
import xssFilter from 'showdown-xss-filter';
import removeMarkdown from 'remove-markdown';
import showdownKatex from '@clevy/showdown-katex';

showdown.setFlavor('github');

const converter = new showdown.Converter({
  literalMidWordUnderscores: true,
  noHeaderId: true,
  omitExtraWLInCodeBlocks: true,
  simplifiedAutoLink: true,
  requireSpaceBeforeHeadingText: true,
  openLinksInNewWindow: true,
  strikethrough: true,
  excludeTrailingPunctuationFromURLs: true,
  extensions: [
    xssFilter,
    showdownKatex({
      delimiters: [{ left: '{latex}', right: '{/latex}', display: false }],
    }),
  ],
});

class MarkdownUtil {
  /**
   * Transform markdown to HTML.
   *
   * @param {String} markdown
   * @return {String}
   */
  static markdownToHtml(markdown: string): string {
    if (!markdown) return '';

    return converter.makeHtml(markdown);
  }

  /**
   * Transform markdown text into
   * raw text (aka without markdown tokens).
   *
   * @param {String} markdown
   * @return {String}
   */
  static markdownToRawText(markdown: string): string {
    if (!markdown) return '';
    return removeMarkdown(markdown);
  }
}

export default MarkdownUtil;
