import { createI18n } from 'vue-i18n';
import en from './en.json';

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages: {
    en,
  },
});
