export enum ColorScheme {
  LIGHT = 'light',
  DARK = 'dark',
  AUTO = 'auto',
  _DEFAULT = 'auto',
}

export enum Theme {
  CLEVY = 'clevy',
  DIAMOND = 'diamond',
  PASTEL = 'pastel',
  GUTENBERG = 'gutenberg',
  _DEFAULT = 'clevy',
}
