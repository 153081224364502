import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import jwtdecode from 'jwt-decode';
import { useRouter } from 'vue-router';

const router = useRouter();

export const useSessionStore = defineStore('session', {
  persist: {
    key: 'clevy-assistant-session',
    paths: ['sessionId', 'jwt'],
  },
  state() {
    return {
      // Unique and anonymous session ID for the user
      sessionId: null as string | null,

      // Authentication token
      jwt: null as string | null,

      audioMode: false,
      contrastMode: false,
      windowFocusState: true,
      isMobile: false,
      autocompleteEnabled: true,

      // Set from the query params
      debugMode: false,
      widgetMode: false,
      hasSidebar: true,
      noNotifications: false,
      metadata: {} as Record<string, unknown>,
      ref: null as string | null,
    };
  },

  actions: {
    /**
     * If no session exists, create one
     */
    init() {
      if (!this.sessionId) this.sessionId = uuidv4();
      const url = new URL(window.location.href);
      const jwt = url.searchParams.get('jwt');

      // remove auth params from URL
      url.searchParams.delete('jwt');
      url.searchParams.delete('profileMetadata');
      url.searchParams.delete('email');
      const modifiedPath = url.pathname + url.search;
      window.history.replaceState({}, document.title, modifiedPath);
      if (jwt) {
        this.setJwt(jwt);
        if (!this.isJwtValid) this.logout();
      }
    },

    /**
     * Force create a new session
     */
    reinit() {
      this.sessionId = uuidv4();
    },

    /**
     * Delete any authentication data
     */
    logout() {
      this.jwt = null;
      router.push('/logout');
    },

    setJwt(jwt: string | null) {
      if (!jwt) {
        this.jwt = null;
      } else {
        this.jwt = jwt;
      }
    },
  },

  getters: {
    decodedJwt(state) {
      if (!state.jwt) return null;
      return jwtdecode(state.jwt);
    },
    isJwtExpired(state): boolean {
      if (!state.jwt || !this.decodedJwt) return false;

      const { exp } = this.decodedJwt;

      // Check expiration time
      const now = new Date();
      const utcTimestamp = Math.floor(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds()
        ) / 1000
      );
      return !exp || exp < utcTimestamp;
    },

    isJwtValid(state): boolean {
      return !!(state.jwt && !this.isJwtExpired);
    },

    getMetadata(state) {
      return state.metadata || {};
    },
  },
});
