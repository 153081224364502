import { DirectiveBinding, ObjectDirective } from 'vue';
import MarkdownUtil from '@/utils/markdown.util';

/**
 * Markdown directive that allow you to transform
 * target element inner HTML with markdown.
 */
const markdown: ObjectDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    el.classList.add('s-markdown', 'prose', 'dark:prose-invert');
    el.innerHTML = MarkdownUtil.markdownToHtml(binding.value);
  },

  updated(el: HTMLElement, binding: DirectiveBinding) {
    el.innerHTML = MarkdownUtil.markdownToHtml(binding.value);
  },
};

export default markdown;
