{
  "MESSAGE_FILE.DOWNLOAD_DEFAULT": "Download File",

  "GENERIC.LOADING": "Loading",
  "GENERIC.PRIVACY_POLICY": "Privacy Policy",
  "GENERIC.ACCESSIBILITY_OPTIONS": "Accessibility Options",
  "GENERIC.ONLINE": "Online",
  "GENERIC.NEW_MESSAGES_SCROLL_DOWN": "You have received new messages! Click here to see them.",
  "GENERIC.SOCKET_DISCONNECTED": "Application is disconnected. Reconnecting in {backoff}ms...",

  "SIDEBAR.FILTER_APPS_BY_KEYWORD": "Filter apps by keyword",
  "SIDEBAR.RESTART_CHAT": "Restart Chat",
  "SIDEBAR.HOW_CAN_I_HELP": "How can I be of assistance?",
  "SIDEBAR.WRITE_YOUR_MESSAGE": "Write your message...",

  "NAVIGATION.BACK": "Back",

  "DEBUG.DEBUG": "Debug",
  "DEBUG.CLEAR_MESSAGES": "Clear Messages",
  "DEBUG.RESET_MEMORY": "Reset Memory",
  "DEBUG.SHOW_SESSION_DATA": "Session Data",
  "DEBUG.SHOW_METADATA": "Metadata",

  "A11Y.AUDIO_MODE": "Audio mode",
  "A11Y.HIGH_CONTRAST": "High contrast mode",

  "APPS.NO_APPS_MATCH": "No app matches your request",

  "INPUT_BAR.PLACEHOLDER": "Write your question here...",

  "AUTOCOMPLETE.SUGGESTIONS": "Suggestions:",

  "CSML_COMPONENT.FIELD_IS_REQUIRED": "This field is mandatory",
  "CSML_INPUT.NUMBER_TO_LOW": "Value is too low",
  "CSML_INPUT.NUMBER_TO_HIGH": "Value is too high",
  "CSML_INPUT.STRING_TOO_SHORT": "Value is too short",
  "CSML_INPUT.STRING_TOO_LONG": "Value is too long",
  "CSML_INPUT.EMAIL_INVALID": "Invalid email value",

  "404.ASSISTANT_DOESNT_EXIST": "This assistant doesn't exist...",
  "404.CONTENT_DOESNT_EXIST": "Oh, no! This content doesn't exist...",
  "404.PLEASE_VISIT_HOMEPAGE": "Visit {clevy} to create your own assistant today!",
  "404.TAKE_ME_THERE": "Take me there!",

  "NOTIFICATIONS.IMAGE": "Image",
  "NOTIFICATIONS.NEW_MESSAGE": "You received a new message!",

  "X": "last item to help with trailing comma"
}
