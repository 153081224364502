import { ColorScheme, Theme } from '@/enums/channel';

export function getMediaPreference() {
  const hasDarkPreference = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches;
  if (hasDarkPreference) {
    return ColorScheme.DARK;
  } else {
    return ColorScheme.LIGHT;
  }
}

export function getLocalColorScheme(): ColorScheme | null {
  return localStorage?.getItem('clevy-assistant-color-scheme') as ColorScheme;
}

export function getCalculatedColorScheme(): ColorScheme {
  return getLocalColorScheme() || getMediaPreference();
}

export function setLocalColorScheme(scheme: ColorScheme) {
  // only set the scheme locally if it's not supposed to be automated
  if (scheme === ColorScheme.DARK || scheme === ColorScheme.LIGHT) {
    localStorage?.setItem('clevy-assistant-color-scheme', scheme);
  }
  // otherwise remove the local saved option to force AUTO mode
  else {
    localStorage?.removeItem('clevy-assistant-color-scheme');
  }
  setDocumentClassName();
}

export function getLocalTheme(): Theme | null {
  return localStorage?.getItem('clevy-assistant-theme') as Theme;
}

export function setLocalTheme(theme: Theme) {
  if (Object.values(Theme).includes(theme)) {
    localStorage?.setItem('clevy-assistant-theme', theme);
  } else {
    localStorage?.setItem('clevy-assistant-theme', Theme._DEFAULT);
  }
  setDocumentClassName();
}

function setDocumentClassName() {
  // const scheme = getLocalColorScheme() || getMediaPreference();
  const scheme = 'light'; // dark mode is currently not supported, force light mode
  const theme = getLocalTheme() || Theme._DEFAULT;
  document.documentElement.className = `c-theme__${theme} ${scheme}`;
}

function getContrastingColor(hexColor: string) {
  let cleanHex = `${hexColor}`;
  // remove leading #
  if (hexColor.startsWith('#')) cleanHex = hexColor.slice(1);
  // if hex is in the form FFF, change it to FFFFFF
  if (cleanHex.length === 3) {
    cleanHex = cleanHex
      .split('')
      .map((c) => `${c}${c}`)
      .join('');
  }
  const r = parseInt(cleanHex.slice(0, 2), 16);
  const g = parseInt(cleanHex.slice(0, 2), 16);
  const b = parseInt(cleanHex.slice(0, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

export function setCssVariables(config: ChannelConfig) {
  const root = document.documentElement;

  if (config.heroCoverImageUrl) {
    root.style.setProperty(
      '--c-theme-hero-bg-image',
      `url('${config.heroCoverImageUrl}')`
    );
  }
  // in case just a background color is set, we shouldn't use the default hero image
  else if (config.heroBackgroundColor) {
    root.style.setProperty(
      '--c-theme-hero-bg-color',
      config.heroBackgroundColor
    );
    root.style.setProperty('--c-theme-hero-bg-image', 'initial');
  }
  if (config.heroTextColor) {
    root.style.setProperty('--c-theme-hero-text-color', config.heroTextColor);
  }
  if (config.headerCoverImageUrl) {
    root.style.setProperty(
      '--c-theme-header-bg-image',
      `url('${config.headerCoverImageUrl}')`
    );
  }
  // in case just a background color is set, we shouldn't use the default header image
  else if (config.headerBackgroundColor) {
    root.style.setProperty(
      '--c-theme-header-bg-color',
      config.headerBackgroundColor
    );
    root.style.setProperty('--c-theme-header-bg-image', 'initial');
  }
  if (config.headerTextColor) {
    root.style.setProperty(
      '--c-theme-header-text-color',
      config.headerTextColor
    );
  }
  if (config.accentColor) {
    root.style.setProperty('--c-theme-accent-color', config.accentColor);
    root.style.setProperty(
      '--c-theme-accent-contrasting-color',
      getContrastingColor(config.accentColor)
    );
  }
  if (config.bubbleColor) {
    root.style.setProperty('--c-theme-bubble-color', config.bubbleColor);
    root.style.setProperty(
      '--c-theme-bubble-text-color',
      getContrastingColor(config.bubbleColor)
    );
  }
}
